import { FC, memo } from 'react'

import { Text, Button } from '@cash-me/react-components'
import NextImage from "next/image"

import { Card } from 'components/card'
import { Section } from 'components/general-styles'
import { Grid, Col } from 'components/grid'
import useWindowSize from 'hooks/useWindowSize'

import * as S from './styles'

const Whatsapp: FC = memo(() => {
  const { isDesktop } = useWindowSize()
  
  const onclick = () => {
    location.href = 'https://api.whatsapp.com/send?phone=551140402550'
  }

  return (
    <Section>
      <Grid col={{ phone: 1, tablet: 2 }}>
        <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
          <S.Infos>
            <Text as="h2" size="heading3" weight="medium">
              Precisa de ajuda com a {' '}
              <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                segunda via do seu boleto
              </Text>
              ?
            </Text>
            <Text color="var(--colors-neutral-text-weak)">
              Agora você pode solicitar a segunda via do seu boleto pelo WhatsApp, de forma simples e rápida.
            </Text>
          </S.Infos>
        </Col>
        <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
          <S.CardsWrapper>
            <Card>
              <S.CardContent>
                <S.ImageWrapper>
                  <NextImage
                    src="/images/contact-us/icon/clock.svg"
                    alt="Relógio"
                    width="80"
                    height="80"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.ImageWrapper>
                <Text size="body1" weight="medium" color="var(--colors-neutral-text-default)">
                  Sem fila de atendimento
                </Text>
              </S.CardContent>
            </Card>
            <Card>
              <S.CardContent>
                <S.ImageWrapper>
                  <NextImage
                    src="/images/contact-us/icon/chat-bubble.svg"
                    alt="Balão de chat"
                    width="80"
                    height="80"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.ImageWrapper>
                <Text size="body1" weight="medium" color="var(--colors-neutral-text-default)">
                  Com segurança e conveniência
                </Text>
              </S.CardContent>
            </Card>
            <Card>
              <S.CardContent>
                <S.ImageWrapper>
                  <NextImage
                    src="/images/contact-us/icon/smartphone.svg"
                    alt="Celular na mão"
                    width="80"
                    height="80"
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "auto"
                    }} />
                </S.ImageWrapper>
                <Text size="body1" weight="medium" color="var(--colors-neutral-text-default)">
                  Disponível a qualquer hora
                </Text>
              </S.CardContent>
            </Card>
          </S.CardsWrapper>
          <Button
            {...(isDesktop
              ? {
                  size: 'lg',
                  css: {
                    margin: '0 auto',
                    minWidth: '19rem'
                  }
                }
              : {
                  size: 'md',
                  css: {
                    margin: '0 auto',
                    width: 'auto'
                  }
                })}
            iconName="arrowRight"
            onClick={onclick}
          >
            Solicitar 2ª via pelo WhatsApp
          </Button>
        </Col>
      </Grid>
    </Section>
  );
})

Whatsapp.displayName = 'Whatsapp'

export default Whatsapp
