import type { NextPage } from 'next'

import { contactUs } from 'data/seo'
import ContactUs from 'views/contact-us'

const ContactUsPage: NextPage = () => <ContactUs />

export default ContactUsPage

export async function getStaticProps() {
  return {
    props: { SEO: contactUs }
  }
}
