import { FC, memo } from 'react'

import { Text, Button, Link } from '@cash-me/react-components'
import NextImage from 'next/image'
import { useRouter } from 'next/router'

import { Card } from 'components/card'
import { Grid, Col } from 'components/grid'
import { Tabs, Tab } from 'components/tabs'
import useWindowSize from 'hooks/useWindowSize'
import { buildSimulatorUrl } from 'utils/buildSimulatorUrl'
import { pushDataLayer } from 'utils/push-data-layer'

import * as S from './styles'

const Hero: FC = memo(() => {
  const { isDesktop } = useWindowSize()
  const router = useRouter()
  const dataLayer = {
    category: 'Clique',
    action: 'Botões',
    label: 'Hero - Fale Conosco - Simular agora'
  }

  return (
    <S.Hero>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col col={{ tablet: 10, desktop: 10 }} start={{ tablet: 2, desktop: 2 }}>
          <S.Infos>
            <Text as="h1" size="heading2" weight="medium">
              Como podemos{' '}
              <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-default)">
                ajudar você
              </Text>
              ?
            </Text>
            <Text uppercase color="var(--colors-neutral-text-weak)">
              Se você tem dúvidas ou precisa de outra ajuda, nossa equipe especializada está pronta para ajudar por meio
              de nossos canais oficiais:
            </Text>
          </S.Infos>
        </Col>
        <Col col={{ tablet: 10, desktop: 10 }} start={{ tablet: 2, desktop: 2 }}>
          <S.Content>
            <Tabs
              activeTabIndex={0}
              dataLayer={[
                {
                  index: 0,
                  category: 'Clique',
                  action: 'Fale Conosco',
                  label: 'Tab Button - Já sou Cliente'
                },
                {
                  index: 1,
                  category: 'Clique',
                  action: 'Fale Conosco',
                  label: 'Tab Button - Ainda não sou Cliente'
                }
              ]}
            >
              <Tab name="Já sou Cliente">
                <S.FirstTabContent>
                  <Text color="var(--colors-neutral-text-default)">
                    Se você já é um cliente CashMe, você pode entrar em contato, de{' '}
                    <Text as="span" color="var(--colors-pink-text-default)">
                      segunda a sexta das 9h as 17h,{' '}
                    </Text>
                    por:
                  </Text>
                  <div>
                    <S.ImageItem>
                      <S.ImageWrapper>
                        <NextImage
                          src="/images/contact-us/icon/telephone.svg"
                          alt="Relógio"
                          width="80"
                          height="80"
                          sizes="100vw"
                          style={{
                            width: '100%',
                            height: 'auto'
                          }}
                        />
                      </S.ImageWrapper>
                      <Text size="body2" color="var(--colors-primary-background-strong-default)">
                        Telefone
                      </Text>
                      <Text color="var(--colors-neutral-text-default)">(11) 3164-9485</Text>
                    </S.ImageItem>
                    <S.ImageItem>
                      <S.ImageWrapper>
                        <NextImage
                          src="/images/contact-us/icon/whatsapp.svg"
                          alt="Relógio"
                          width="80"
                          height="80"
                          sizes="100vw"
                          style={{
                            width: '100%',
                            height: 'auto'
                          }}
                        />
                      </S.ImageWrapper>
                      <Text size="body2" color="var(--colors-primary-background-strong-default)">
                        WhatsApp para 2ª via de boleto
                      </Text>
                      <Text color="var(--colors-neutral-text-default)">(11) 4040-2550</Text>
                    </S.ImageItem>
                  </div>
                  <Text color="var(--colors-neutral-text-default)">Ou através dos nossos e-mails:</Text>
                  <S.CardsWrapper>
                    <Card border="BDCEFF">
                      <S.CardContent>
                        <Text size="body1" weight="medium" color="var(--colors-blue-background-strong-default)">
                          Suporte a Pagamento
                        </Text>
                        <Text size="body2" color="var(--colors-neutral-text-default)">
                          Para dúvidas e auxílio em assuntos relacionados ao seu contrato, como boleto, antecipação,
                          quitação e demais assuntos financeiros:
                        </Text>
                        <Link href="mailto:suporte.pagamento@cashme.com.br">
                          <Text size="body2" color="var(--colors-neutral-text-default)">
                            suporte.pagamento@cashme.com.br
                          </Text>
                        </Link>
                      </S.CardContent>
                    </Card>
                    <Card border="BDCEFF">
                      <S.CardContent>
                        <Text size="body1" weight="medium" color="var(--colors-blue-background-strong-default)">
                          Suporte ao Registro
                        </Text>
                        <Text size="body2" color="var(--colors-neutral-text-default)">
                          Para você tirar dúvidas na fase de Registro de Imóvel:
                        </Text>
                        <Link href="mailto:suporte.registro@cashme.com.br">
                          <Text size="body2" color="var(--colors-neutral-text-default)">
                            suporte.registro@cashme.com.br
                          </Text>
                        </Link>
                      </S.CardContent>
                    </Card>
                    <Card border="BDCEFF">
                      <S.CardContent>
                        <Text size="body1" weight="medium" color="var(--colors-blue-background-strong-default)">
                          Outros Assuntos
                        </Text>
                        <Text size="body2" color="var(--colors-neutral-text-default)">
                          Para falar sobre simulações e dúvidas em geral:
                        </Text>
                        <Link onClick={() => document.getElementById('contact-form')?.scrollIntoView()}>
                          <Text size="body2" color="var(--colors-neutral-text-default)">
                            Clique aqui
                          </Text>
                        </Link>
                      </S.CardContent>
                    </Card>
                  </S.CardsWrapper>
                </S.FirstTabContent>
              </Tab>
              <Tab name="Ainda não sou Cliente">
                <S.SecondTabContent>
                  <Text color="var(--colors-neutral-text-default)">
                    Se você ainda não é nosso cliente, faça uma <b>simulação de crédito</b> e conheça as vantagens da
                    CashMe.
                  </Text>
                  <Button
                    {...(isDesktop
                      ? {
                          size: 'lg',
                          css: {
                            minWidth: '19rem'
                          }
                        }
                      : {
                          size: 'md',
                          css: {
                            width: 'auto'
                          }
                        })}
                    iconName="arrowRight"
                    onClick={() => {
                      pushDataLayer(dataLayer)
                      location.href = buildSimulatorUrl(router.pathname)
                    }}
                  >
                    Simular agora
                  </Button>
                  <Text color="var(--colors-neutral-text-weak)">
                    Ainda tem dúvidas? Entre em contato com o e-mail:{' '}
                    <Link href="mailto:fale.conosco@cashme.com.br">fale.conosco@cashme.com.br</Link>
                  </Text>
                </S.SecondTabContent>
              </Tab>
            </Tabs>
          </S.Content>
        </Col>
      </Grid>
    </S.Hero>
  )
})

Hero.displayName = 'Hero'

export default Hero
