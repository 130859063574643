import styled from 'styled-components'

import { Infos as GSInfos } from 'components/general-styles'
import { theme } from 'styles/theme'

const { space, media } = theme

export const Infos = styled(GSInfos)`
  text-align: center;
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};
  overflow-x: auto;
  margin-bottom: ${space['spacing-lg']};

  @media (${media.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 300px;
  padding: ${space['spacing-md']};
`

export const ImageWrapper = styled.div`
  position: relative;
  margin: ${space['spacing-md']} 0 ${space['spacing-lg']};
  width: 80px;

  > * {
    position: relative;
  }

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    transform: translate(-10px, -10px);
    border-radius: 100%;
    width: 100px;
    height: 100px;
    background: rgb(215 235 255) !important;
  }
`
