import { FC, memo } from 'react'

import { Text } from '@cash-me/react-components'

import { Section } from 'components/general-styles'
import { Grid, Col } from 'components/grid'

import ContactForm from './form'
import * as S from './styles'

const Contact: FC = memo(() => (
  <Section bg="medium" id="contact-form">
    <Grid col={{ phone: 1, tablet: 12 }}>
      <Col col={{ tablet: 10, desktop: 5 }} start={{ tablet: 2, desktop: 2 }}>
        <S.Infos>
          <Text className="subtitle" size="caption" weight="medium" uppercase>
            FALE COM A CASHME
          </Text>
          <Text as="h2" size="heading2" weight="medium">
            Quer falar com a gente sobre {' '}
            <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-default)">
              outros assuntos?
            </Text>
          </Text>
        </S.Infos>
      </Col>
      <Col col={{ tablet: 10, desktop: 5 }} start={{ tablet: 2, desktop: 7 }}>
        <S.FormWrapper>
          <ContactForm id="hero" />
        </S.FormWrapper>
      </Col>
    </Grid>
  </Section>
))

Contact.displayName = 'Contact'

export default Contact