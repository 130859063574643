import { FC, memo } from 'react'

import Script from 'next/script'

import { DynamicForm } from 'components/general-styles'

const ContactForm: FC<{ id: string }> = memo(() => {
  const divId = 'dpcjirtDIbAOR3L-aVUIASVOFv-l45430CdxhmPnBXUA'
  const dataFormBlockId = '7d60f606-0825-ef11-840a-002248d2dd17'
  const dataWebsiteId = 'pcjirtDIbAOR3L-aVUIASVOFv-l45430CdxhmPnBXUA'
  const dataHostname = '0d2e458eb3414b53a27a6f182ebf4cca.svc.dynamics.com'

  return (
    <DynamicForm>
      <div data-form-block-id={dataFormBlockId} />
      <div id={divId} />
      <div
        className="d365-mkt-config"
        style={{ display: 'none' }}
        data-website-id={dataWebsiteId}
        data-hostname={dataHostname}
      />

      <Script
        id="FormLoader"
        src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007"
        strategy="lazyOnload"
      />
      <Script
        id="FormLoader"
        src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"
        strategy="lazyOnload"
      />
    </DynamicForm>
  )
})

ContactForm.displayName = 'ContactForm'

export default ContactForm
