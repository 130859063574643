import { memo } from 'react'

import dynamic from 'next/dynamic'

import { CtaBar } from 'modules/cta-bar'
import { Layout } from 'modules/layout'

import Hero from './hero'

const Whatsapp = dynamic(() => import('./whatsapp'), { loading: () => <section /> })
const Form = dynamic(() => import('./contact'), { loading: () => <section /> })
const Help = dynamic(() => import('./help'), { loading: () => <section /> })

const ContactUs = memo(() => {
  const handleOpenModal = () => {
    document.getElementById('contact-form')?.scrollIntoView();
  }

  return (
  <Layout hasCTA>
    <Hero />
    <Whatsapp />
    <Form />
    <Help />
    <CtaBar title="Entre em contato" buttonLabel="Enviar mensagem" hideTablet onClick={() => handleOpenModal()} />
  </Layout>
  )
})

ContactUs.displayName = 'ContactUs'

export default ContactUs
