import { Text } from '@cash-me/react-components'

import { ItemType } from 'modules/help'

export const accordion = [
  {
    id: 'faq-0',
    title: 'Quem é a CashMe?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: 'A CashMe é uma fintech, ou seja, uma empresa de tecnologia do ramo financeiro.'
      },
      {
        id: '2',
        type: 'text' as ItemType,
        content: `Nós nascemos com a missão de combater a burocratização do processo de aquisição de 
        crédito com a ajuda da tecnologia.`
      }
    ]
  },
  {
    id: 'faq-1',
    title: 'Como faço para saber o valor da parcela?',
    item: [
      {
        id: '1',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Você pode consultar o valor da parcela do seu contrato via nossos canais de suporte:
            </Text>
            <br />
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Whatsapp: (11) 4040-2550;
              <br />
              E-mail: suporte.pagamento@cashme.com.br
            </Text>
            <br />
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Lembrando que nosso horário de atendimento é de segunda a sexta-feira, das 9h às 17h.
            </Text>
          </>
        )
      }
    ]
  },
  {
    id: 'faq-2',
    title: 'Posso antecipar as parcelas?',
    item: [
      {
        id: '1',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Sim. Basta você entrar em contato com a gente via nossos canais de suporte:
            </Text>
            <br />
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Whatsapp: (11) 4040-2550;
              <br />
              E-mail: suporte.pagamento@cashme.com.br
            </Text>
            <br />
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Lembrando que nosso horário de atendimento é de segunda a sexta-feira, das 9h às 17h.
            </Text>
          </>
        )
      }
    ]
  },
  {
    id: 'faq-3',
    title: 'Como funciona a Carência Adicional CashMe?',
    item: [
      {
        id: '1',
        type: 'node' as ItemType,
        content: (
          <>
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Os clientes pessoa jurídica da CashMe adquirem o direito de solicitar a carência adicional para seus
              pagamentos, ajudando a aliviar as contas e se restabelecer financeiramente.
            </Text>
            <br />
            <Text size="body2" color="var(--colors-neutral-text-weak)">
              Para que o pedido de carência seja aceito, ele deve passar por análise interna e validação de todas as
              obrigações contratuais.
            </Text>
          </>
        )
      }
    ]
  }
]
