import styled from 'styled-components'

import { Infos as GSInfos } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, space, fontSizes, colors, radii } = theme

export const Infos = styled(GSInfos)`
  @media (${media.desktop}) {
    h2,
    h2 > span {
      margin-bottom: ${space['spacing-xxl']};
      font-size: ${fontSizes['font-size-heading-1']};
    }

    p {
      margin-bottom: ${space['spacing-xxl']};
    }
  }
`

export const FormWrapper = styled.div`
  background: ${colors['neutral-background-default']};
  padding: ${space['spacing-md']};
  border-radius: ${radii['corner-radius-lg']};
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);

  div[data-form-block-id] .onFormSubmittedFeedback .onFormSubmittedFeedbackMessage {
    font: inherit !important;
  }
`
