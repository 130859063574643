import styled from 'styled-components'

import { Infos as GSInfos } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, space, colors, radii } = theme

export const Hero = styled.section`
  width: 100%;
  padding-bottom: ${space['spacing-xxxl']};
  background: #f3f6ff;

  @media (${media.tablet}) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
`

export const Infos = styled(GSInfos)`
  text-align: center;

  h1 {
    margin-bottom: ${space['spacing-xl']};
  }
`

export const Content = styled.div`
  & > div:first-child {
    display: flex;
    justify-content: center;

    button > span {
      text-transform: unset;
    }

    button[data-state='active']::after {
      content: unset;
    }

    button[data-state='active'] {
      border-bottom: 2px solid ${colors['blue-background-strong-default']};
      padding: ${space['spacing-xs']} ${space['spacing-lg']};

      span {
        color: ${colors['blue-background-strong-default']};
      }
    }
  }

  & > div:first-child + div {
    background: ${colors['neutral-background-default']};
    margin-top: unset;
    padding: ${space['spacing-md']};
    border-radius: ${radii['corner-radius-md']};
    box-shadow: 0 4px 16px 0 rgba(0 0 0 / 10%);
  }
`

export const FirstTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-md']};
`

export const SecondTabContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${space['spacing-md']};
  width: 100%;

  a {
    color: ${colors['neutral-text-weak']};
    text-decoration: underline;
  }

  a:hover::after {
    content: unset;
  }
`

export const ImageItem = styled.div`
  display: inline-grid;
  grid-template-areas:
    'image heading'
    'image description';
  width: fit-content;
  gap: 0 ${space['spacing-md']};

  &:first-child {
    margin-bottom: ${space['spacing-lg']};
  }

  p:first-child {
    grid-area: heading;
  }

  p:last-child {
    grid-area: description;
  }

  @media (${media.tablet}) {
    &:first-child {
      margin-bottom: unset;
      margin-right: 10rem;
    }
  }
`

export const ImageWrapper = styled.div`
  grid-area: image;
  width: ${space['spacing-xxl']};
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};
  overflow-x: auto;

  @media (${media.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  gap: ${space['spacing-md']};
  padding: ${space['spacing-md']};

  a {
    cursor: pointer;
    text-decoration: underline;
    margin-right: auto;
  }

  a:hover::after {
    content: unset;
  }
`
